import React                   from 'react';
import { graphql }             from 'gatsby';
import Img                     from 'gatsby-image';
import styled                  from '@emotion/styled';
import { Container, Row, Col } from 'react-grid-system'
import { Button }              from '@chakra-ui/react';

import CallToAction      from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import Heading           from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer            from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper           from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import RandomBrandHeaderImages
                         from '@interness/brands-addon/src/components/media/RandomBrandHeaderImages/RandomBrandHeaderImages';
import MoreExpander      from '@interness/web-core/src/components/structure/MoreExpander/MoreExpander';
import Link              from '@interness/web-core/src/components/elements/Link/Link';
import LiveAnnouncements from '@interness/web-core/src/components/structure/LiveAnnouncements/LiveAnnouncements';

import Newsletter from '../components/newsletter';
import Separator  from '@interness/web-core/src/components/structure/Separator/Separator';
import Carousel   from '@interness/web-core/src/components/media/Carousel/Carousel';

const List = styled.ul`
  margin-left: 0;

  li {
    margin: 0;
  }
`;


function IndexPage(props) {
  return (
    <>
      <Carousel>
        {props.data.headerImages.images.map((image) => (
          <Img key={image.file.localFile.name} fluid={image.file.localFile.childImageSharp.fluid}
               alt={image.file.localFile.name}/>
        ))}
      </Carousel>
      <Wrapper>
        <Spacer/>
        <LiveAnnouncements/>
        <section>
          <Heading subtitle={'Herzlich Willkommen'}>Optik Sauer</Heading>
          <span style={{ textAlign: 'justify' }}>
            <p>Optik Sauer, im Herzen der schönen Stadt Meschede gelegen, ist seit vielen Jahren ein Begriff für aussergewöhnliche Sehhilfen und meisterliche Beratung. In unseren modernen Geschäftsräumen möchten wir Ihnen ein noch schöneres Einkaufserlebnis ermöglichen. Wir überzeugen mit bestem Service, aktuellen Modetrends und individueller Beratung.</p>
            <p>Blicken Sie gelassen in die Zukunft. Unser hochqualifiziertes Personal, in unserem Meisterbetrieb, berät Sie gern zur perfekten Sehhilfe oder Sonnenbrille. Modisch aktuell, scharf und klar und mit dem besonderen persönlichen Stil.</p>
            <p>BIS BALD IN MESCHEDE<br/>
                IHRE FAMILIE HERRMANN</p>
          </span>
        </section>
        <section>
          <Heading tag={'h2'}>Unser Service für Sie</Heading>
          <p>Kompletter Service ist für unser Team selbstverständlich. So erfüllen wir Ihre Wünsche schnell, sicher und
            mit persönlichem Ansprechpartner.</p>
          <MoreExpander>
            <Container fluid>
              <Row>
                <Col md={6}>
                  <h3>Augenglasbestimmung</h3>
                  <List>
                    <li>Individuelle Messung mit dem DNEye Scanner</li>
                  </List>
                </Col>
                <Col md={6}>
                  <h3>Individuelle Auswahl der Brillengläser</h3>
                  <List>
                    <li>Ferngläser</li>
                    <li>Nahgläser</li>
                    <li>Gleitsichtgläser</li>
                    <li>Bildschirmarbeitsplatzgläser</li>
                    <li>Sonnenschutzgläser</li>
                    <li>Sportgläser</li>
                  </List>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <h3>Individuelle Fassungsauswahl</h3>
                  <List>
                    <li>Passend zu Ihrer Persönlichkeit</li>
                    <li>Abgestimmt auf Ihre Anforderung</li>
                  </List>
                </Col>
                <Col md={6}>
                  <h3>Kontaktlinsenberatung und Anpassung</h3>
                  <List>
                    <li>Einstärkenkontaktlinse</li>
                    <li>Mehrstärkenkontaktlinse</li>
                  </List>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <h3>Service und Reparaturen in unserer eigenen Werkstatt</h3>
                  <List>
                    <li>Lötungen</li>
                    <li>Brillen richten</li>
                    <li>Nasenpads wechseln</li>
                  </List>
                </Col>
                <Col md={6}>
                  <h3>Sonstige Serviceleistungen</h3>
                  <List>
                    <li>Vergrößernde Sehhilfen</li>
                    <li>Amtlicher Führerscheinsehtest</li>
                    <li>Brillenabo</li>
                    <li><Link target="_blank" external to={props.data.flyer.media[0].file.localFile.publicURL}>Digitales
                      Augenscreening</Link></li>
                  </List>
                </Col>
              </Row>
            </Container>
          </MoreExpander>
        </section>
        <Spacer/>
        <section>
          <Heading tag={'h2'} subtitle="Mit der kostenlosen Grafix App">Jetzt virtuell Brillen anprobieren</Heading>
          <Img fluid={props.data.grafix.media[0].file.localFile.childImageSharp.fluid} alt="Grafix"/>
          <Spacer height={30} />
          <Button as={Link} to={'https://apps.apple.com/de/app/grafix-4-0/id1450301394'} isFullWidth colorScheme='brand' variant='solid' target='_blank' rel='noopener noreferrer'>Zum iOS App
            Store</Button>
        </section>
        <Separator />
        <section>
          <Heading tag={'h2'}>Newsletter</Heading>
          <Newsletter/>
        </section>
        <Spacer/>
        <div style={{ textAlign: 'center' }}>
          <CallToAction/>
        </div>
        <Spacer/>
      </Wrapper>
    </>
  )
}

export default IndexPage;

export const query = graphql`
    query {
        headerImages: directusMediaCollection(name: {eq: "home-header"}) {
            name
            images: media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 1980, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
        flyer: directusMediaCollection(name: {eq: "index-flyer"}) {
            name
            media {
                file {
                    localFile {
                        publicURL
                    }
                }
            }
        }
        grafix: directusMediaCollection(name: {eq: "grafix"}) {
            name
            media {
                file {
                    localFile {
                        childImageSharp {
                            fluid(quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;