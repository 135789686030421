import React     from 'react';
import useScript from 'react-script-hook';

import Spinner, { SpinnerContainer } from '@interness/web-core/src/components/modules/Spinner/Spinner';

const Newsletter = () => {

  const [loading] = useScript({
    src: 'https://tool.rodenstock.com/newsletter/frontend/load.js',
    checkForExisting: true
  });

  return (
    <>
      {loading && <SpinnerContainer><Spinner/></SpinnerContainer>}
      <div id="rs_bn_newsletter" data-accessid="7b4dd6143a218f0863ff932678186eee" data-language="de"/>
    </>
  )
};

export default Newsletter;